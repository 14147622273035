<template>
  <div
    class="h-[50px] text-[#999] bg-white w-full flex justify-center items-center"
  >
    <a
      class="hover:text-[#333] hover:underline"
      href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
    >粤ICP备2022136097号-1</a>
    <b>｜</b>
    <span>Copyright &copy; 2022-{{ year }} 深圳叉子科技有限公司</span>
    <b>｜</b>
    <a
      class="text-primary hover:underline"
      href="#"
    >《用户协议》</a>
    <b>｜</b>
    <a
      class="hover:text-[#333] hover:underline"
      href="#"
    >联系我们</a>
  </div>
</template>

<script lang="ts" setup>
const year = ref(0)

const getYear = () => {
  year.value = new Date().getFullYear()
}
getYear()
</script>
